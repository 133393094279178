import {App, createApp} from 'vue'
import './style.css'
import AppElement from './App.vue'
import router from "./router";
import {createPinia} from 'pinia'
import "./styles/main.scss"
import * as Sentry from "@sentry/vue";

import * as Dashboards from '@highcharts/dashboards';
import "./custom/quickfact-component.js"
// Importeer verschillende filter-componenten
import "./custom/filter-component-select.js"
import "./custom/filter-component-multiselect.js"
import "./custom/filter-component-year-range.js"
import "./custom/filter-component-quarter-range.js"
import "./custom/filter-component-month-range.js"
import "./custom/filter-component-date-range.js"
import "./custom/map-component-choropleth.js"
import "./custom/map-component-points.js"
import JSONAuthConnector from "./connectors/jsonauth/JSONAuthConnector.ts";
import {baseUri} from "./config/config.ts";
import {AccountStore} from "./stores/accountStore.ts";
import {dexieLoader} from "./config/dexieLoader.ts";
import {useMainStore} from "./stores/pinia/mainStore.ts";
import {replayIntegration} from "@sentry/vue";

// Maak de Vue-app aan
const app: App<Element> = createApp(AppElement);

const isLocalhost = window.location.hostname === 'localhost';

// Set the Sentry environment
const envName = isLocalhost ? import.meta.env.VITE_APP_SENTRY_ENVIRONMENT ?? 'development_unknown' : 'production';
const sentryEnabled = isLocalhost? import.meta.env.VITE_APP_SENTRY_ENABLED === 'true' : true;

//On vite build, set the correct base path


Sentry.init({
    dsn: "https://16e6015e2f70090be96fa4928b65023a@o4507180065095680.ingest.de.sentry.io/4507209169043536",
    integrations: [
        replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        })
    ],
    tracePropagationTargets: ["localhost", /^https:\/\/.*\.highcharts\.com/, /^https:\/\/.*\.data\.xitres\.io/],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: envName,
    enabled: sentryEnabled
});


// Pinia store setup
const pinia = createPinia();
app.use(pinia);

// Router setup
app.use(router);

// Registreer JSONAuthConnector voor Dashboards
//@ts-ignore
Dashboards.DataConnector.registerType('JSONAuth', JSONAuthConnector);

dexieLoader().then(() => {
    app.mount('#app'); // Mount de app nadat de data is geladen
    const mainStore = useMainStore();
    mainStore.setLoaded('geo', true);
});

fetch(baseUri + '/health').then(async (response) => {
    if (!response.ok) {
        window.location.href = "/50x.html";
    }

    //Check if token is not expired etc.
    await AccountStore.initTokenCheck();
});
